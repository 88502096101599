// Generated by Framer (25dafa9)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, getPropertyControls, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";
import { Rating } from "https://framerusercontent.com/modules/pAiDzuVWlJ5Yhal7oozz/lEyCaN6Gj2ZBiPWlj3VK/Rating.js";
const RatingFonts = getFonts(Rating);
const RatingControls = getPropertyControls(Rating);

const cycleOrder = ["BmQg9Di7C"];

const variantClassNames = {BmQg9Di7C: "framer-v-19vp9q7"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; active?: string; inactive?: string; hover?: string; rating?: number; amount?: number; size?: number; step?: string; icon?: boolean; type?: string; onEnter?: boolean }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "BmQg9Di7C", active: X1W5tHyNw = "var(--token-b541229c-f587-4519-b855-78e19c4396b4, rgb(59, 40, 204)) /* {\"name\":\"Primary / 500\"} */", inactive: N2QgmkFdd = "var(--token-79f17ac7-2be2-41f3-8485-68fa5f222413, rgb(209, 213, 219)) /* {\"name\":\"Gray / 300\"} */", hover: tQrBHOS0q = "rgba(0, 0, 0, 0.1)", rating: gJcD3pan5 = 3, amount: Bcma1bLUL = 5, size: ogCNydkub = 24, step: dqisiSqOX = 0.5, icon: mwSasaq6h = true, type: XXMg6WJ2y = "Star", onEnter: NJVvy4Pzu = false, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "BmQg9Di7C", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-fj8cZ", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-19vp9q7", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"BmQg9Di7C"} ref={ref} style={{...style}} transition={transition}><motion.div className={"framer-tiogtb-container"} layoutDependency={layoutDependency} layoutId={"IOzZ310YU-container"} transition={transition}><Rating amount={Bcma1bLUL} color={X1W5tHyNw} customIcon={mwSasaq6h} height={"100%"} hoverColor={tQrBHOS0q} iconName={XXMg6WJ2y} id={"IOzZ310YU"} inactiveColor={N2QgmkFdd} layoutId={"IOzZ310YU"} onLoadReset={NJVvy4Pzu} rating={gJcD3pan5} size={ogCNydkub} step={dqisiSqOX} width={"100%"}/></motion.div></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-fj8cZ [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-fj8cZ .framer-xj4evi { display: block; }", ".framer-fj8cZ .framer-19vp9q7 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 33px; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: 140px; }", ".framer-fj8cZ .framer-tiogtb-container { flex: none; height: auto; position: relative; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-fj8cZ .framer-19vp9q7 { gap: 0px; } .framer-fj8cZ .framer-19vp9q7 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-fj8cZ .framer-19vp9q7 > :first-child { margin-left: 0px; } .framer-fj8cZ .framer-19vp9q7 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 33
 * @framerIntrinsicWidth 140
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"X1W5tHyNw":"active","N2QgmkFdd":"inactive","tQrBHOS0q":"hover","gJcD3pan5":"rating","Bcma1bLUL":"amount","ogCNydkub":"size","dqisiSqOX":"step","mwSasaq6h":"icon","XXMg6WJ2y":"type","NJVvy4Pzu":"onEnter"}
 */
const Framervx3MsDQkL: React.ComponentType<Props> = withCSS(Component, css, "framer-fj8cZ") as typeof Component;
export default Framervx3MsDQkL;

Framervx3MsDQkL.displayName = "Elements / Rating";

Framervx3MsDQkL.defaultProps = {height: 33, width: 140};

addPropertyControls(Framervx3MsDQkL, {X1W5tHyNw: {defaultValue: "var(--token-b541229c-f587-4519-b855-78e19c4396b4, rgb(59, 40, 204)) /* {\"name\":\"Primary / 500\"} */", title: "Active", type: ControlType.Color}, N2QgmkFdd: {defaultValue: "var(--token-79f17ac7-2be2-41f3-8485-68fa5f222413, rgb(209, 213, 219)) /* {\"name\":\"Gray / 300\"} */", title: "Inactive", type: ControlType.Color}, tQrBHOS0q: {defaultValue: "rgba(0, 0, 0, 0.1)", title: "Hover", type: ControlType.Color}, gJcD3pan5: {defaultValue: 3, displayStepper: true, max: 10, min: 0, step: 0.5, title: "Rating", type: ControlType.Number}, Bcma1bLUL: {defaultValue: 5, displayStepper: true, max: 10, min: 1, title: "Amount", type: ControlType.Number}, ogCNydkub: {defaultValue: 24, displayStepper: true, max: 100, min: 5, title: "Size", type: ControlType.Number}, dqisiSqOX: RatingControls?.["step"] && {...RatingControls["step"], defaultValue: 0.5, hidden: undefined, title: "Step"}, mwSasaq6h: {defaultValue: true, title: "Icon", type: ControlType.Boolean}, XXMg6WJ2y: RatingControls?.["iconName"] && {...RatingControls["iconName"], defaultValue: "Star", hidden: undefined, title: "Type"}, NJVvy4Pzu: {defaultValue: false, title: "On Enter", type: ControlType.Boolean}} as any)

addFonts(Framervx3MsDQkL, [...RatingFonts])